import React, { useCallback, useRef, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";

function Overall_piechart({ data }) {
  const windowHeight = useRef(window.innerHeight);
  const [activeIndex, setActiveIndex] = useState(1);


  let dataArray = [[]];
  let pieData = [
    {
      id: 15200,
      value: data[0]?.PERCENTAGE,
      name: "",
    },
    {
      id: 15450,
      value: 100 - data[0]?.PERCENTAGE,
      name: "",
    },
  ];

  pieData?.forEach((ele, ind) => {
    dataArray.push({
      id: ind,
      value: ele?.value,
      name: "Testing",
    });
    //   dataArray.push({
    //     id: ind,
    //     value: ele?.value,
    //     name: "Testing",
    //   });
  });

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={
            // data?.PERCENTAGE >= 0 && data?.PERCENTAGE < 30
            //   ? "#EB5757"
            //   : data?.PERCENTAGE >= 30 && data?.PERCENTAGE < 50
            //   ? "rgba(235, 87, 87, 0.50)"
            //   : // ? "#EE4E4E"
            //   data?.PERCENTAGE >= 50 && data?.PERCENTAGE < 80
            //   ? "#F2C94C"
            //   : data?.PERCENTAGE >= 80 && data?.PERCENTAGE < 90
            //   ? "rgba(33, 150, 83, 0.75"
            //   : "#219653"
            "#007cc3"
          }
        >
          {data[0]?.PERCENTAGE}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        {/* <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          /> */}
        {/* <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          /> */}
        {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
        {/* <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
          >{value}</text>: <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
          >₹ {`${new Intl.NumberFormat("en-IN").format(value)}`}</text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
          >
            {`(${(percent * 100).toFixed(2)}%)`}
          </text> */}
      </g>
    );
  };
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const COLORS = [
    "white",
    data?.PERCENTAGE >= 0 && data?.PERCENTAGE < 30
      ? "#EB5757"
      : data?.PERCENTAGE >= 30 && data?.PERCENTAGE < 50
      ? "rgba(235, 87, 87, 0.50)"
      : // ? "#EE4E4E"
      data?.PERCENTAGE >= 50 && data?.PERCENTAGE < 80
      ? "#F2C94C"
      : data?.PERCENTAGE >= 80 && data?.PERCENTAGE < 90
      ? "rgba(33, 150, 83, 0.75"
      : "#219653",

  ];
  return (
    // <div>
    <div
      style={{ width: "100%", height: "400px" }}
      //  style={{marginTop:"5%",pointerEvents:"none"}}
    >
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={480}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={dataArray}
            //  cx={windowHeight?.current>900?300:windowHeight?.current>760?240:windowHeight.current<760&&windowHeight.current>=740?165:190}
            //  cy={windowHeight?.current>900?255:windowHeight?.current<650?220:210}
            cx={windowHeight.current<760&&windowHeight.current>=740?"37%":"40%"}
            cy={windowHeight?.current>900?"75%":"65%"}
            innerRadius={55}
            outerRadius={75}
            // innerRadius={50}
            // outerRadius={90}
            fill="white"
            dataKey="value"
            onMouseEnter={onPieEnter}
            // paddingAngle={1}
          >
            {pieData?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={"#007cc3"}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
    // </div>
  );
}

export default Overall_piechart;
