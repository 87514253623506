import React, { Component, useState } from "react";
import styles from "../Styles/Table.module.css";
import MUIDataTable from "mui-datatables";
import { saveAs } from "file-saver";
import moment from "moment";
import axios from "axios";
import {  ThemeProvider, createTheme, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import addWeeks from "date-fns/addWeeks";
import { DatePicker, Space } from "antd";


// import { createTheme, ThemeProvider } from '@mui/material/styles';

// const theme = createTheme({
//   components:{
//     MUIDataTableFooter:{
//       styleOverrides:{
//         root:{
//           zIndex:1,
//         }
//       }
//     }
//   }
// });

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: "none", // Removes shadow
          border:"1px solid #d4d4d4"
        },
      },
    },
  },
});

function Collection_tbl({ data, setFile_Path, selected_flag, count_flag ,setRow_Status,handleMonth,
  selectedMonth}) {
  const storage = window.sessionStorage;
  // const classes = useStyles();

  const [staus_flag,setStatus_Flag] = useState(null);

  const [selectedFromDate, setSelectedFromDate] = useState([]);
  const [selectedToDate, setSelectedToDate] = useState([]);
  const [date_change_flag,setDate_Change_Flag] = useState(false)


  const [filteredRows,setFilteredRows] = useState(data)


  const handleClick = (row,data) => {
    //   (async () => {
    //     let blob = await fetch(row).then((r) => r.blob());
    //     saveAs(blob);
    //  })();
    // saveAs(row);

    // axios({
    //   url: row,
    //   method: "GET",
    //   mode: "no-cors",
    // })
    //   .then((res) => {
    //     res.blob();
    //   })
    //   .then((blob) => {
    //     saveAs(blob);
    //   })
    //   .catch((err) => {
    //     
    //   });

    //  let pdf_url = row;
    //  fetch(pdf_url)
    //  .then(response=>response.blob())
    //  .then(blob=>{
    //   saveAs(blob)
    //  })
    //  .catch(error=>{
    //   
    //  })
//  if(data?.rowData[1] == 1){
  saveAs(row);
//  }
 
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [hover_flag, setHover_Flag] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [file_list, setFile_list] = useState([]);

  const handleRow = (data_row) => {

    // if (!hover_flag && data_row[1] == "1") {
       if(!hover_flag) {    
      setRow_Status(data_row[1])
        setFile_Path(data_row[0][0]?.url);
       }

    // }
  };

  function handleClose() {
    setAnchorEl(null);
    setHover_Flag(false)
  }

  const handleView = (row, data, e) => {
    e.stopPropagation();
    setFile_list(row);
    setAnchorEl(e.currentTarget);
    setHover_Flag(true)
    setStatus_Flag(data?.rowData[1])
  };

  const handleSelectDateRange = (dateRange)=>{
    if (dateRange) {
      const startDate = moment(dateRange[0]).format("YYYY-MM-DD");
      const endDate = moment(dateRange[1]).format("YYYY-MM-DD");
      setSelectedFromDate(dateRange[0])
      setSelectedToDate(dateRange[1])
      const filteredData = data.filter((row) => {
        const rowDate = moment(row.date_of_collection).format("YYYY-MM-DD");
        return rowDate >= startDate && rowDate <= endDate;
      });
      setFilteredRows(filteredData);
      setDate_Change_Flag(true)
    } else {
      setSelectedFromDate(null)
      setSelectedToDate(null)
      setFilteredRows(data); // Reset if no date range is selected
      setDate_Change_Flag(false)
    }
  }

  const predefinedRanges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
      placement: "left",
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: "left",
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
    },
    {
      label: "Last week",
      value: [
        startOfWeek(addWeeks(new Date(), -1)),
        endOfWeek(addWeeks(new Date(), -1)),
      ],
      placement: "left",
    },
    // {
    //   label: 'Last 7 days',
    //   value: [subDays(new Date(), 6), new Date()],
    //   placement: 'left'
    // },
    // {
    //   label: 'Last 30 days',
    //   value: [subDays(new Date(), 29), new Date()],
    //   placement: 'left'
    // },
    {
      label: "This month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
    },
    {
      label: "All time",
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: "left",
    },
    
  ];



  const columns = [
    {
      name: "reports_files",
      label: "reports_files",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "payment_status",
      label: "payment_status",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "date_of_collection",
      label: "Date Of Collection",
      options: {
        filter: false,
        sort: false,
        // filterType: 'custom',
        // customFilterListOptions: {

        // },
        // filterOptions: {
        //   logic: (age, filters) => {

        //   },
        //   display: (filterList, onChange, index, column) => (
        //     <div>
        //       <label id={styles.filter_label} htmlFor="">Collection Date</label>
        //      <DateRangePicker
        //             format="dd MMM yyyy"
        //             ranges={predefinedRanges}
        //             value={!date_change_flag?[]:[selectedFromDate, selectedToDate]}
        //             onChange={handleSelectDateRange}
        //             style={{ width: "100%" }}
        //             size="md"
        //             placement="bottomEnd"
        //             placeholder="Select Collection Date"
        //             preventOverflow
        //           /> 
        //     </div>
        //   ),
        // },
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <span style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </span>
          );
        },
      },
    },
    {
      name: "site_name",
      label: "Site Name",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: () => {
          return (
            <>  
                <p style={{ textAlign:"left",marginLeft:"8px" }}>Site Name</p>
            </>
          );
        },
        customBodyRender: (row, data) => {
          
          return (
            <div style={{ textAlign: "left",paddingLeft:"10px" }}>
            <span >
              {row}
            </span>
            </div>
          );
        },
      },
    },
    {
      name: "description",
      label: "Sample Description",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "source_of_sample",
      label: "Source",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ padding: "10px" }}>{row}</p>;
        },
      },
    },
    {
      name: "date_of_report",
      label: "Date Of Report",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },
    {
      name: "report_sent_date",
      label: "Report Sent Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },
    {
      name: "reports_files",
      label: "reports files",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row,data) => {
  
          return (
            <>
              <div
              
                //  onMouseLeave={handleClose}
              >
                <p id={styles.view_btn} onMouseEnter={(e) => handleView(row, data, e)}>View</p>

              </div>

        {/* <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        MenuListProps={{ onMouseLeave: handleClose }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {file_list?.map((ele, index) => (

    <MenuItem onClick={() => handleClick(ele?.url,data)}>
            <span>file {index + 1}</span>
            <img
              src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
              style={{
                height: "20px",
                marginLeft: "10%",
                cursor: "pointer",
                // opacity: data?.rowData[1] == 1?"1":"0.3"
              }}
              onClick={() => handleClick(ele?.url,data)}
            />
          </MenuItem>
          
        ))}
      </Menu> */}
            <ThemeProvider theme={theme}>
          <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                MenuListProps={{ onMouseLeave: handleClose }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {file_list?.map((ele, index) => (
  
                  <div>
           
                 {staus_flag != 1?  <MenuItem >
                    <span>file {index+1}</span>
      
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
                      style={{
                        height: "20px",
                        marginLeft: "10%",
                        cursor: "pointer",
                        opacity:"0.3"
                      }}
                    />
                  </MenuItem>:
                 <MenuItem onClick={() => handleClick(ele?.url)}>
                 <span>file {index+1}</span>
                 
                 <img
                   src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
                   style={{
                     height: "20px",
                     marginLeft: "10%",
                     cursor: "pointer",
                   }}
                   onClick={() => handleClick(ele?.url)}
                 />
               </MenuItem>
                 
                  }
                   </div>
                ))}
              </Menu>
              </ThemeProvider>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <div id="collection_tbl">
      {/* <ThemeProvider theme={theme}> */}
      <MUIDataTable
        // title={
        //   <h4 id={styles.list_head}>
        //     Collection Table
        //   </h4>
        // }
        data={filteredRows}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          responsive: "standard",
          viewColumns: false,
          print: false,
          download: false,
          search: false,
          filter: true,
          // pagination: false,
          rowsPerPageOptions: [10, 25, 50, 100],
          tableBodyMaxHeight: "40vh",
          scroll: false,
          onRowClick: (rowData) => {
    
            // if(!hover_flag){
              handleRow(rowData);
            // }

          },

          sortOrder: {
            name: "date_of_collection",
            direction: "desc",
          },
          setRowProps: () => ({
            style: {
              cursor: "pointer",
            },
          }),
          textLabels: {
            body: {
              noMatch:
                selected_flag == "" && count_flag
                  ? "Please Select tab to get Report List"
                  : "Data Not Available",
            },
          },
          customToolbar:()=>{
            return(
              <>
                             <Space direction="vertical" id={styles.monthPicker2}>
                  <DatePicker
                    onChange={handleMonth}
                    picker="month"
                    value={selectedMonth}
                    format="MMM-YYYY"
                  />
                </Space>
              </>
            )
          }
        }}
      />

      {/* <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem >Organisation Details</MenuItem>

            <MenuItem >Switch Account</MenuItem>
            <MenuItem
              sx={{ justifyContent: "center", color: "#007cc3" }}
          
            >
              Signout
            </MenuItem>
          </Menu> */}

      {/* </ThemeProvider> */}
    </div>
  );
}

export default Collection_tbl;











      {/* <div className={styles.dropdown}>
                <span style={{ color: "#007cc3" }}>View</span>
                <div   onMouseEnter={() => setHover_Flag(true)}
                     onMouseLeave={() => setHover_Flag(false)} className={styles.dropdown_content}>
                  {row?.map((ele, index) => (
                    <div

                      onClick={() => handleClick(ele?.url)}
                    >
                      <span>file{index + 1}</span>
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
                        style={{
                          height: "20px",
                          marginLeft: "10%",
                          cursor: "pointer",
                        }}
                        onClick={() => handleClick(ele?.url)}
                      />
                    </div>
                  ))}
                </div>
              </div> */}